<template>
  <div class="post-event-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PostEventTemplateItemList',
};
</script>

<style scoped>
.post-event-list .post-event-template:first-child {
  @apply mt-0;
}
.post-event-list .post-event-template {
  @apply mt-8;
}
</style>
