<template>
  <asterix-form-modal
    v-if="wipTemplate"
    :title="modalTitle"
    closable
    @submit="onFormSubmit"
    @cancel="$emit('input', null)"
  >
    <template #content>
      <form-row>
        <sun-label-group text="Name" class="w-full">
          <asterix-input
            v-model="wipTemplate.name"
            class="w-full"
            :minlength="3"
            :maxlength="50"
            validate-on-blur
            placeholder="Template name..."
            required="required"
          />
        </sun-label-group>
      </form-row>
      <form-row>
        <template #left>
          <sun-label-group text="Gross Price">
            <currency-selector
              v-model="wipTemplate.grossPrice"
              default-currency="EUR"
              currency-disabled
              placeholder="2.00"
              :max-decimals="maxDecimals"
              required="required"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="Net Price">
            <currency-selector
              v-model="wipTemplate.netPrice"
              default-currency="EUR"
              currency-disabled
              placeholder="2.00"
              :max-decimals="maxDecimals"
              required="required"
            />
          </sun-label-group>
        </template>
      </form-row>
      <form-row>
        <template #left>
          <sun-label-group text="Unique">
            <sun-select
              v-model="uniqueConversion"
              :options="uniqueConversionOptions"
              required="required"
              text-error="This field is required"
              label="name"
              track-by="value"
              close-on-select
            >
            </sun-select>
          </sun-label-group>
        </template>
      </form-row>
    </template>
    <template #footer>
      <div class="text-left text-sm flex">
        <save-button :text="saveButtonText" class="custom-p-1" :loading="isLoading" />
        <sun-button variant="pill" class="custom-p-1 ml-2" color="white" @click="$emit('input', null)">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import SaveButton from '@/components/atoms/SaveButton';
import { UNIQUE_CONVERSION_OPTIONS } from '@/model/modules/ecommerce/offer/TypePixelOptions';

export default {
  name: 'PostEventTemplateModal',
  components: {
    AsterixInput,
    FormRow,
    CurrencySelector,
    AsterixFormModal: () => import('@/components/organisms/shared/AsterixFormModal'),
    SaveButton,
  },
  props: {
    /** @type PostEventTemplate */
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      wipTemplate: null,
      isLoading: false,
      maxDecimals: 6,
      uniqueConversionOptions: UNIQUE_CONVERSION_OPTIONS,
    };
  },
  computed: {
    isNew() {
      return !this.value?.id;
    },
    modalTitle() {
      return this.isNew ? 'New Post Event Template' : 'Edit Post Event Template';
    },
    saveButtonText() {
      return this.isNew ? 'Create' : 'Update';
    },
    uniqueConversion: {
      get() {
        return this.uniqueConversionOptions.find(({ value }) => value === this.wipTemplate.unique);
      },
      set(conversion) {
        this.wipTemplate.unique = conversion?.value || 0;
      },
    },
  },
  watch: {
    value(newValue) {
      this.wipTemplate = newValue;
    },
  },
  created() {
    this.wipTemplate = this.value;
  },
  methods: {
    onFormSubmit(form) {
      if (form.valid) {
        this.isLoading = true;
        this.$emit('input', this.wipTemplate);
        this.$emit('change');
      }
    },
  },
};
</script>

<style scoped></style>
