<template>
  <div class="flex post-event-template items-start">
    <div v-if="template" class="w-full border rounded flex p-4 items-center text-sm flex-wrap">
      <p class="text-left mb-2 flex-1 lg:mb-0 font-bold">
        {{ template.name }}
      </p>
      <div id="post-event-wrapper" class="flex items-center w-full mt-2 md:mt-0 md:w-auto">
        <sun-label-group text="Unique">
          <sun-select
            v-model="uniqueConversion"
            :options="uniqueConversionOptions"
            label="name"
            track-by="value"
            disabled
          >
          </sun-select>
        </sun-label-group>
        <sun-label-group text="Gross Price">
          <currency-selector
            v-model="template.grossPrice"
            class="mx-2"
            default-currency="EUR"
            currency-disabled
            :disabled="!template.toggle"
          />
        </sun-label-group>
        <sun-label-group text="Net Price">
          <currency-selector
            v-model="template.netPrice"
            class="mx-2"
            default-currency="EUR"
            currency-disabled
            :disabled="!template.toggle"
          />
        </sun-label-group>
      </div>
      <div class="flex justify-end w-full mt-2 lg:mt-0 lg:w-auto">
        <button class="ml-2 p-1 hover:bg-gray-400 rounded-full" @click.stop="$emit('edit', template)">
          <edit-svg class="w-5" />
        </button>
      </div>
    </div>

    <button
      class="flex justify-center text-gray-600 ml-3 p-2 rounded-full cursor-pointer hover:bg-red-300 hover:text-red-900"
      :class="{ invisible: !canDelete }"
      @click="emitDelete"
    >
      <close-svg class="w-4" />
    </button>
  </div>
</template>

<script>
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import CloseSvg from '@/components/icons/CloseSvg';
import EditSvg from '@/components/icons/EditSvg';
import { UNIQUE_CONVERSION_OPTIONS } from '@/model/modules/ecommerce/offer/TypePixelOptions';
import POST_EVENT_TEMPLATE_TYPES from '@/model/modules/ecommerce/PostEventTemplateTypes';

export default {
  name: 'PostEventTemplateItem',
  components: {
    CurrencySelector,
    CloseSvg,
    EditSvg,
  },
  props: {
    template: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      uniqueConversionOptions: UNIQUE_CONVERSION_OPTIONS,
    };
  },
  computed: {
    canDelete() {
      return this.template?.offersCount === 0 && this.template?.type?.id !== POST_EVENT_TEMPLATE_TYPES.PRESET.id;
    },
    uniqueConversion() {
      return this.uniqueConversionOptions.find(({ value }) => value === this.template.unique);
    },
  },
  methods: {
    emitDelete() {
      if (this.canDelete) {
        this.$emit('delete', this.template);
      }
    },
  },
};
</script>
<style scoped>
#post-event-wrapper >>> .sun-label {
  @apply font-semibold text-gray-600;
}
</style>
