var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.wipTemplate
    ? _c("asterix-form-modal", {
        attrs: { title: _vm.modalTitle, closable: "" },
        on: {
          submit: _vm.onFormSubmit,
          cancel: function ($event) {
            return _vm.$emit("input", null)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "form-row",
                    [
                      _c(
                        "sun-label-group",
                        { staticClass: "w-full", attrs: { text: "Name" } },
                        [
                          _c("asterix-input", {
                            staticClass: "w-full",
                            attrs: {
                              minlength: 3,
                              maxlength: 50,
                              "validate-on-blur": "",
                              placeholder: "Template name...",
                              required: "required",
                            },
                            model: {
                              value: _vm.wipTemplate.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.wipTemplate, "name", $$v)
                              },
                              expression: "wipTemplate.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Gross Price" } },
                                [
                                  _c("currency-selector", {
                                    attrs: {
                                      "default-currency": "EUR",
                                      "currency-disabled": "",
                                      placeholder: "2.00",
                                      "max-decimals": _vm.maxDecimals,
                                      required: "required",
                                    },
                                    model: {
                                      value: _vm.wipTemplate.grossPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.wipTemplate,
                                          "grossPrice",
                                          $$v
                                        )
                                      },
                                      expression: "wipTemplate.grossPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Net Price" } },
                                [
                                  _c("currency-selector", {
                                    attrs: {
                                      "default-currency": "EUR",
                                      "currency-disabled": "",
                                      placeholder: "2.00",
                                      "max-decimals": _vm.maxDecimals,
                                      required: "required",
                                    },
                                    model: {
                                      value: _vm.wipTemplate.netPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.wipTemplate,
                                          "netPrice",
                                          $$v
                                        )
                                      },
                                      expression: "wipTemplate.netPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2607424626
                    ),
                  }),
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                { attrs: { text: "Unique" } },
                                [
                                  _c("sun-select", {
                                    attrs: {
                                      options: _vm.uniqueConversionOptions,
                                      required: "required",
                                      "text-error": "This field is required",
                                      label: "name",
                                      "track-by": "value",
                                      "close-on-select": "",
                                    },
                                    model: {
                                      value: _vm.uniqueConversion,
                                      callback: function ($$v) {
                                        _vm.uniqueConversion = $$v
                                      },
                                      expression: "uniqueConversion",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3295722754
                    ),
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-left text-sm flex" },
                    [
                      _c("save-button", {
                        staticClass: "custom-p-1",
                        attrs: {
                          text: _vm.saveButtonText,
                          loading: _vm.isLoading,
                        },
                      }),
                      _c(
                        "sun-button",
                        {
                          staticClass: "custom-p-1 ml-2",
                          attrs: { variant: "pill", color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("input", null)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3583648773
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }