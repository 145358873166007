var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex post-event-template items-start" }, [
    _vm.template
      ? _c(
          "div",
          {
            staticClass:
              "w-full border rounded flex p-4 items-center text-sm flex-wrap",
          },
          [
            _c(
              "p",
              { staticClass: "text-left mb-2 flex-1 lg:mb-0 font-bold" },
              [_vm._v(" " + _vm._s(_vm.template.name) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "flex items-center w-full mt-2 md:mt-0 md:w-auto",
                attrs: { id: "post-event-wrapper" },
              },
              [
                _c(
                  "sun-label-group",
                  { attrs: { text: "Unique" } },
                  [
                    _c("sun-select", {
                      attrs: {
                        options: _vm.uniqueConversionOptions,
                        label: "name",
                        "track-by": "value",
                        disabled: "",
                      },
                      model: {
                        value: _vm.uniqueConversion,
                        callback: function ($$v) {
                          _vm.uniqueConversion = $$v
                        },
                        expression: "uniqueConversion",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "sun-label-group",
                  { attrs: { text: "Gross Price" } },
                  [
                    _c("currency-selector", {
                      staticClass: "mx-2",
                      attrs: {
                        "default-currency": "EUR",
                        "currency-disabled": "",
                        disabled: !_vm.template.toggle,
                      },
                      model: {
                        value: _vm.template.grossPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.template, "grossPrice", $$v)
                        },
                        expression: "template.grossPrice",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "sun-label-group",
                  { attrs: { text: "Net Price" } },
                  [
                    _c("currency-selector", {
                      staticClass: "mx-2",
                      attrs: {
                        "default-currency": "EUR",
                        "currency-disabled": "",
                        disabled: !_vm.template.toggle,
                      },
                      model: {
                        value: _vm.template.netPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.template, "netPrice", $$v)
                        },
                        expression: "template.netPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex justify-end w-full mt-2 lg:mt-0 lg:w-auto" },
              [
                _c(
                  "button",
                  {
                    staticClass: "ml-2 p-1 hover:bg-gray-400 rounded-full",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("edit", _vm.template)
                      },
                    },
                  },
                  [_c("edit-svg", { staticClass: "w-5" })],
                  1
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c(
      "button",
      {
        staticClass:
          "flex justify-center text-gray-600 ml-3 p-2 rounded-full cursor-pointer hover:bg-red-300 hover:text-red-900",
        class: { invisible: !_vm.canDelete },
        on: { click: _vm.emitDelete },
      },
      [_c("close-svg", { staticClass: "w-4" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }