<template>
  <asterix-section :status="sectionStatus">
    <template #header-right>
      <sun-button
        variant="pill"
        class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
        color="gray"
        @click="showCreatePostEvent"
      >
        + New Post Event Template
      </sun-button>
    </template>

    <template #content>
      <section class="p-8 bg-white rounded shadow-md">
        <post-event-template-item-list v-if="!isLoading">
          <post-event-template-item
            v-for="template in visibleItems"
            :key="template.id"
            :template="template"
            @delete="templateToDelete = $event"
            @edit="templateToEdit = $event.clone()"
          />
        </post-event-template-item-list>

        <post-event-template-item-list v-else>
          <post-event-template-loader
            v-for="dummy in Array(5).map((_, index) => index++)"
            :key="dummy"
          ></post-event-template-loader>
        </post-event-template-item-list>
      </section>

      <post-event-template-modal v-if="templateToEdit" v-model="templateToEdit" @change="onPostEventChange" />

      <delete-modal
        :open="templateToDelete ? true : false"
        @confirm="confirmDeleteModal"
        @cancel="templateToDelete = null"
      >
        <template #description> {{ modalDeleteDescription }}</template>
      </delete-modal>
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import {
  createPostEventTemplate,
  deletePostEventTemplate,
  getPostEventTemplates,
  updatePostEventTemplate,
} from '@/services/modules/ecommerce/postEventTemplate';
import PostEventTemplateItem from '@/components/organisms/modules/ecommerce/PostEventTemplateItem';
import PostEventTemplateItemList from '@/components/atoms/PostEventTemplateItemList';
import PostEventTemplateModal from '@/components/organisms/modules/ecommerce/PostEventTemplateModal';
import PostEventTemplate from '@/entities/ecommerce/PostEventTemplate';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import PostEventTemplateLoader from '@/components/atoms/Loaders/PostEventTemplateLoader';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'PostEventTemplateList',
  components: {
    PostEventTemplateModal,
    PostEventTemplateItemList,
    PostEventTemplateItem,
    AsterixSection,
    PostEventTemplateLoader,
    DeleteModal,
  },
  mixins: [queryParamsMixin, activeClientMixin, indexMixin],
  data: () => ({
    items: [],
    currentApi: new EcommerceApi(),
    templateToDelete: null,
    deletedItems: [],
    templateToEdit: null,
    isLoadingModal: false,
  }),
  computed: {
    ...mapGetters({
      contextActive: ACTIVE_CONTEXT,
    }),
    visibleItems() {
      // Since we are using Optimistic UI, we are take count of the removed and created items
      return this.items.filter(item => !this.deletedItems.includes(item.id));
    },
    modalDeleteDescription() {
      return `You are going to remove ${this.templateToDelete?.name}. This action can not be undone.`;
    },
  },
  mounted() {
    this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    async onPostEventChange() {
      if (this.templateToEdit?.id) {
        this.isLoadingModal = true;
        await updatePostEventTemplate(this.templateToEdit);
        this.createToast(Toast.success('Success!', 'Template updated'));
        this.isLoadingModal = false;
      } else {
        const { data } = await createPostEventTemplate(this.templateToEdit, this.activeClient.id);
        this.templateToEdit.id = data.postEvent.id;
        this.createToast(Toast.success('Success!', 'Template created'));
      }

      this.updateItemList();

      this.templateToEdit = null;
    },

    updateItemList() {
      const editIndex = this.items.findIndex(item => item.id === this.templateToEdit.id);
      if (editIndex !== -1) {
        this.$set(this.items, editIndex, this.templateToEdit);
      } else {
        this.items.push(this.templateToEdit);
      }
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getPostEventTemplates);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    async confirmDeleteModal() {
      await deletePostEventTemplate(this.templateToDelete.id);
      this.deletedItems.push(this.templateToDelete.id);

      this.templateToDelete = null;
      this.createToast(Toast.success('Success!', 'Template deleted'));
    },
    showCreatePostEvent() {
      this.templateToEdit = new PostEventTemplate();
    },
  },
};
</script>
