var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "sun-button",
              {
                staticClass:
                  "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                attrs: { variant: "pill", color: "gray" },
                on: { click: _vm.showCreatePostEvent },
              },
              [_vm._v(" + New Post Event Template ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "section",
              { staticClass: "p-8 bg-white rounded shadow-md" },
              [
                !_vm.isLoading
                  ? _c(
                      "post-event-template-item-list",
                      _vm._l(_vm.visibleItems, function (template) {
                        return _c("post-event-template-item", {
                          key: template.id,
                          attrs: { template: template },
                          on: {
                            delete: function ($event) {
                              _vm.templateToDelete = $event
                            },
                            edit: function ($event) {
                              _vm.templateToEdit = $event.clone()
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _c(
                      "post-event-template-item-list",
                      _vm._l(
                        Array(5).map((_, index) => index++),
                        function (dummy) {
                          return _c("post-event-template-loader", {
                            key: dummy,
                          })
                        }
                      ),
                      1
                    ),
              ],
              1
            ),
            _vm.templateToEdit
              ? _c("post-event-template-modal", {
                  on: { change: _vm.onPostEventChange },
                  model: {
                    value: _vm.templateToEdit,
                    callback: function ($$v) {
                      _vm.templateToEdit = $$v
                    },
                    expression: "templateToEdit",
                  },
                })
              : _vm._e(),
            _c("delete-modal", {
              attrs: { open: _vm.templateToDelete ? true : false },
              on: {
                confirm: _vm.confirmDeleteModal,
                cancel: function ($event) {
                  _vm.templateToDelete = null
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "description",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.modalDeleteDescription))]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }